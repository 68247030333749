.contract {
    padding: 10rem 0;
    position: relative;
}

.contract .contract-title {
    font-weight: 600;
    text-align: center;
    font-size: 4.6rem;
    color: var(--text-primary);
}

.contract .contract-title_des {
    font-weight: 300;
    font-size: 1.9rem;
    text-align: center;
    color: var(--text-primary);
}

.contract .contract-address .contract-address_wrapper {
    display: flex;
    justify-content: space-between;
}

.contract .contract-address .contract-address_wrapper .contract-address_info {
    display: flex;
    align-items: center;
}

.contract .contract-address .contract-address_wrapper .contract-address_info p:first-child {
    font-weight: 500;
    font-size: 1.9rem;
    color: var(--text-color);
    width: 30%;
}

.contract .contract-address .contract-address_wrapper .contract-address_info p:last-child {
    font-weight: 300;
    font-size: 1.9rem;
    color: var(--text-color);
    width: 70%;
}

.contract_firm-name {
    font-weight: 300;
    font-size: 1.9rem;
    color: var(--text-primary);
}

.contract-maps iframe {
    width: 100%;
    height: 30rem;
}

.contract-maps {
    width: 45%;
}

.contract-address-tag {
    width: 55%;
}

.contract-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contract-form .form-wrapper .contract-policy {
    font-weight: 300;
    font-size: 1.9rem;
    color: var(--text-primary);
    text-align: center;
}

.form-wrapper {
    width: 80%;
}

.contract-privacy {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-wrapper .wrapper-btn {
    text-align: center;
    margin-top: 3rem;
}

.form-wrapper .wrapper-btn .button-submit {
    padding: 2rem 5.1rem;
    border-radius: 2rem;
    background-color: var(--text-accent);
    box-shadow: 0 1.4rem 3.7rem #5CC1FF70;
    outline: none;
    border: none;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
}

.contract-gradient {
    position: absolute;
    top: -20rem;
    right: 0rem;
    z-index: -1;
}

@media (max-width:490px) {
    .contract {
        padding: 0 1rem;
    }

    .contract .contract-address .contract-address_wrapper {
        flex-direction: column;
    }

    .contract-address-tag {
        width: 100%;
    }

    .contract-maps {
        width: 100%;
        margin-top: 2rem;
    }

    .form-wrapper {
        width: 100%;
    }

    .contract-form {
        margin-bottom: 3rem;
    }

    .contract .contract-address .contract-address_wrapper .contract-address_info p:last-child {
        font-size: 1.5rem;
    }
}