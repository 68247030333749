.base_button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 2rem 2.5rem;
    color: var(--text-second);
    border-radius: 1.5rem;
    font-weight: 500;
    font-size: 1.9rem;
    border: 1px solid var(--text-second);
    background-color: transparent;
    cursor: pointer;
}

.base_button div {
    width: 80%;
}

.base_button img {
    width: 20%;
    height: auto;
}

@media(max-width:490px) {
    .base_button {
        gap: 1rem;
        padding: 1rem 1.5rem;
        font-size: 1.5rem;
    }
}