.header-wrapper {
  position: relative;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
}

.header_menu-mobile {
  display: none;
}

.border-header {
  border-bottom: 1px solid var(--border-header);
}

.header-wrapper .header_logo img {
  width: 17.2rem;
  cursor: pointer;
}

.header-wrapper .header_menu {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.header-wrapper .header_menu .header_menu-item {
  list-style: none;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--text-header);
}

.header-wrapper .header_menu .header_menu-item.active {
  color: var(--text-second);
  position: relative;
  line-height: 1.9rem;
}

.header-wrapper .header_menu .header_menu-item.active::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -1rem;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 0.4rem;
  border-radius: 0.6rem;
  background-color: var(--border-primary);
}

@media (max-width: 490px) {
  .header_menu {
    display: none !important;
  }

  .header_menu-mobile {
    display: block;

  }

  .header_menu-mobile .header_menu-icon {
    position: relative;
    z-index: 2;
    background-color: var(--text-primary);
    padding: 1rem;
  }

  .header_menu-mobile .header_menu-icon img {
    width: 100%;
    height: 2rem;
  }

  .header_menu-mobile ul {
    /* display: none; */
    position: absolute;
    top: 0;
    right: 0rem;
    padding: 2rem;
    z-index: 1;
    margin: 0;
    background-color: var(--text-primary);
    width: 50vw;
    transform: translateY(-100%);
    transition: 0.3s ease all;

  }

  .header_menu-mobile ul.open {
    transform: translateY(0%);
    transition: 0.3s ease all;
  }

  .header_menu-mobile ul li {
    list-style: none;
    color: var(--text-white);
    margin: 3rem 0;
    padding-left: 1rem;
    font-size: 1.5rem;
  }

  .header-wrapper .header_logo img {
    width: 15rem;
  }

}