.footer {
  background-color: #171c61;
}

.footer_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6rem 3rem;
}

.footer_wrapper .footer_logo {
  width: 17.2rem;
  height: 2.9rem;
  cursor: pointer;
}

.footer_wrapper .footer_logo img {
  width: 100%;
  height: 100%;
}

.footer_wrapper .footer_nav {
  display: flex;
  /* align-items: center; */
  gap: 3rem;
}

.footer_wrapper .footer_nav .footer_item {
  list-style: none;
  color: var(--text-white);
  text-transform: uppercase;
  font-size: 1.9rem;
  font-weight: 300;
  cursor: pointer;
}

.footer_wrapper .footer_nav .footer_item .footer_item-sub {
  margin: 0;
  padding: 0;
}

.footer_wrapper .footer_nav .footer_item .footer_item-sub .footer_item-subItem {
  margin: 2rem 0;
  list-style: none;
}

.footer .footer-policies {
  display: flex;
  justify-content: flex-end;
  gap: 4rem;
  margin-top: 4rem;
}

.footer .footer-policies a {
  color: var(--text-white);
  font-size: 1.9rem;
}

.footer .footer_coppyright {
  margin-top: 2rem;
  border-top: 1px solid #454981;
  padding: 3rem 0;
  font-size: 1.5rem;
  color: var(--text-white);
}

@media (max-width: 490px) {
  .footer_wrapper {
    flex-direction: column;
    padding: 4rem 1.5rem;
    box-sizing: border-box;
  }

  .footer_wrapper .footer_nav {
    flex-direction: column;
    padding: 0;
    margin: 0;
    margin-top: 4rem;
  }

  .footer .footer-policies {
    justify-content: center;
  }

  .footer_wrapper .footer_nav .footer_item {
    font-size: 1.5rem;
  }

  .footer .footer_coppyright {
    text-align: center;
  }

  .footer .footer-policies a {
    font-size: 1.2rem;
  }
}