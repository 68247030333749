.brand {
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.brand-section {
    position: relative;
}

.brand-section .brand-section-title {
    font-weight: 600;
    font-size: 3.5rem;
    color: var(--text-second);
    text-align: center;

}

.brand-section .brand-section-des {
    font-weight: 400;
    font-size: 3.5rem;
    color: var(--text-second);
    text-align: center;
}

.brand-section .brand-section_content {
    display: flex;
    align-items: center;
    gap: 6rem;
}

.brand-section .brand-section_content.reverse {
    flex-direction: row-reverse;
    gap: 6rem;
}


.brand-section .brand-section_content .brand-section_content-description {
    font-weight: 300;
    padding: 8rem 0rem 8rem 8rem;
    font-size: 1.9rem;
    color: var(--text-primary);
    width: 50%;

}

.brand-section .brand-section_content .brand-section_content-img {
    width: 50%;
}

.brand-section .brand-section_content .brand-section_content-img img {
    width: 100%;
}

.brand-gradient {
    position: absolute;
    top: -14rem;
    right: -0rem;
    z-index: -1;
}

@media (max-width:490px) {
    .brand {
        padding: 0 1rem;
        box-sizing: border-box;
    }

    .brand-section .brand-section-des {
        font-size: 2rem;
    }

    .brand-section .brand-section_content {
        flex-direction: column;
    }

    .brand-section .brand-section_content .brand-section_content-description {
        width: 100%;
        padding: 0;
    }

    .brand-section_content-img {
        width: 100% !important;
    }

    .brand-section .brand-section_content.reverse {
        flex-direction: column-reverse;
        gap: 1rem;
    }
}