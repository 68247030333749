.news {
    padding-bottom: 10rem;
}

.news-gradient {
    position: absolute;
    right: 0;
    top: -2rem;
    z-index: -1;
    height: 100vh;
}

.home_blog-card-vertical.news {
    width: 45% !important;
}

.home_blog-card-list.news {
    width: 54% !important;
}

@media (max-width: 490px) {

    .news {
        padding: 0 1rem;
        box-sizing: border-box;
    }

    .home_blog .home_blog-card-wrapper .home_blog-card-vertical .home_blog-card-vertical--img {
        width: 100%;
    }

    .home_blog .home_blog-card-wrapper .home_blog-card-vertical.news {
        width: 100% !important;
    }

    .home_blog-card-list.news {
        width: 100% !important;
    }
}