.baseSelect-wrapper select {
    width: 100%;
    padding: 1.5rem;
    border: 1px solid #CECECE;
    border-radius: .9rem;
    outline-color: rgb(107, 107, 218);
    font-size: 1.9rem;
    color: #707275;

}

.baseSelect-wrapper label {
    display: block;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}