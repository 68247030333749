@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Be Vietnam Pro', sans-serif !important;
  box-sizing: border-box !important;
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 0.694vw !important;
  }
}

:root {
  --text-primary: #142349;
  --text-second: #000000;
  --text-white: white;
  --text-title: #252641;
  --text-accent: #7ebcdc;
  --text-link: #3366ff;
  --text-header: #7b86a4;
  --text-pagination: #727272;
  --background-blur: #45cdf7;

  --padding-wrapper: 8rem;

  --border-primary: #0e20ff;
  --border-header: #c2d1ff;

  --width-container: 144.4rem;
}

.wrapper-container {
  padding: 0 10rem;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: var(--width-container);
  margin: auto;
}

.highlight {
  color: var(--text-accent);
}

.mt-12 {
  margin-top: 12rem;
}

@media screen and (max-width: 490px) {
  html {
    font-size: 10px !important;
  }

  .wrapper-container {
    padding: 0 1rem;
  }
}
