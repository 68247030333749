.company {
    padding: 8rem 0;
}

.company-title {
    font-weight: 600;
    font-size: 4.6rem;
    text-align: center;
}

.company-info {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 2rem;
    border-bottom: 1px solid #B8D1BE;
}

.company-info_label {
    font-weight: 600;
    width: 30%;
    font-size: 1.6rem;
    color: var(--text-second);
}

.company-info_content {
    font-weight: 400;
    font-size: 1.6rem;
    width: 70%;
    color: var(--text-second);

}

.company-achievements {
    margin-top: 2rem;
}

.company-achievements img {
    width: 100%;
    height: 20.5rem;
}

@media(max-width:490px) {
    .company {
        padding: 0 1rem;
    }

    .company-info {
        flex-direction: column;
    }

    .company-info_label {
        width: 100%;
    }

    .company-info_content {
        width: 100%;
    }

    .company-achievements img {
        object-fit: contain;
    }
}